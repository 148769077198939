footer {
    width: 100%;
    background-color: var(--clr-secondary-blue);
    padding: 6rem 0 1rem 0;
    color: var(--clr-neutral-white);
    line-height: 1.7rem
}

.footer-content {
    width: 83.33%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-box {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    /* border: solid red; */
}

.footer-column {
    width: 30%;
    height: 100%;
    min-height: 180px;
    text-align: left;
    /* border: solid black; */
}

.footer-column:nth-of-type(2) {
    width: 25%;
}

.footer-column:nth-of-type(3) {
    width: 45%;
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
}

.footer-logo img {
    width: 140px;
}

.footer-logo h2 {
    width: 140px;
    font-family: 'Rage', cursive;
    font-size: 1.5rem;
    color: var(--clr-neutral-white);
    text-align: left;
}

.footer-sitemap,
.footer-contact {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    color: var(--clr-neutral-white);
}

.footer-sitemap ul h4,
.footer-contact ul h4 {
    margin-bottom: 1rem;
}

.footer-sitemap a {
    color: var(--clr-neutral-white);
}

.footer-contact li {
    display: flex;
    justify-content: flex-start;
    margin-bottom: .5rem;
    gap: .5rem;
}

.footer-map {
    width: 25%;
    height: 180px;
}

.map-container {
    width: 100%;
    height: 100%;
}

.footer-copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-copyright p {
    font-size: .8em;
    color: var(--clr-neutral-white);
    text-align: center;
}

@media (max-width: 1400px) {
    .footer-map {
        width: 100%;
    }

    .footer-content {
        margin-bottom: 2rem;
        /* border: solid red; */
    }

    .footer-box {
        width: 100%;
        margin-bottom: 2rem;
        /* border: solid green */
    }

    .footer-logo,
    .footer-sitemap,
    .footer-contact {
        align-items: center;
    }

    .footer-map {
        margin-bottom: 2rem;
    }
}

@media (max-width: 768px) {
    .footer-content {
        width: 100%;
    }

    .footer-box {
        width: 100%;
    }

    .footer-column {
        width: 100%;
        min-height: unset;
        text-align: center;
        /* border: solid black; */
    }

    .footer-column:nth-of-type(2) {
        width: 100%;
    }

    .footer-column:nth-of-type(3) {
        width: 100%;
    }

    .footer-logo img {
        width: 20%;
    }

    .footer-logo,
    .footer-sitemap {
        align-items: center;
        margin-bottom: 3em;
    }

    .footer-contact {
        align-items: center;
    }

    .footer-contact li {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    .footer-icons {
        display: block;
    }

    .footer-content,
    .footer-links ul {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .footer-links {
        margin-bottom: 2rem;
    }

    .footer-map {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .footer-logo img {
        width: 30%;
    }
}