.home-banho-tosa-service-container {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 6rem;
    /* border: solid red; */
}

.home-banho-tosa-service-content {
    width: 100%;
    padding: 2rem 0;
    background-color: var(--clr-primary-blue);
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /* border: solid green; */
}

.home-banho-tosa-service-box {
    width: 72.5%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    z-index: 2;
    overflow-x: hidden;
}

.home-banho-tosa-service-item {
    width: 33.33%;
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    /*  border: solid red; */
}

.home-banho-tosa-service-item:nth-of-type(2)::after {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 2;
    border-left: solid var(--clr-tertiary-blue);
    border-right: solid var(--clr-tertiary-blue);
}

/* .home-banho-tosa-service-item:nth-of-type(2) {
    border-left: solid var(--clr-tertiary-blue);
    border-right: solid var(--clr-tertiary-blue);
} */

.home-banho-tosa-service-title {
    width: 100%;
    display: flex;
    /* margin-bottom: 4px; */
}

.home-banho-tosa-service-icon {
    width: 24px;
    height: 24px;
    margin-right: .5rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.home-banho-tosa-service-icon-01 {
    background-image: url('../../../assets/drying.svg');
}

.home-banho-tosa-service-icon-02 {
    background-image: url('../../../assets/shaving.svg');
}

.home-banho-tosa-service-icon-03 {
    background-image: url('../../../assets/clipping.svg');
}

.home-banho-tosa-service-icon-04 {
    background-image: url('../../../assets/sales.svg');
}

.home-banho-tosa-service-text {
    width: 100%;
    /* border: solid red; */
}

.home-banho-tosa-service-item h4,
.home-banho-tosa-service-item p {
    font-size: .9rem;
}

.home-banho-tosa-service-image {
    width: 22.5%;
    position: relative;
    z-index: 2;
}

.home-banho-tosa-service-image img {
    width: 105%;
    position: absolute;
    top: -17rem;
    right: 2rem;
}

/* .home-banho-tosa-service-bg {
    width: 100%;
    background-image: url('../../../assets/hero-paws-bg.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
} */

@media only screen and (min-width: 1600px) {
    .home-banho-tosa-service-content {
        padding: 4rem 0;
    }
}

@media only screen and (max-width: 1200px) {
    .home-banho-tosa-service-container {
        padding-bottom: 4rem;
    }

    .home-banho-tosa-service-box {
        width: 50%;
        height: 100%;
        flex-direction: column;
    }

    .home-banho-tosa-service-item {
        width: 90%;
        padding: 1rem 2rem;
    }

    .home-banho-tosa-service-item:nth-of-type(2) {
        border-left: none;
        border-right: none;
        border-top: solid var(--clr-tertiary-blue);
        border-bottom: solid var(--clr-tertiary-blue);
    }

    .home-banho-tosa-service-item:nth-of-type(2)::after {
        display: none;
    }

    .home-banho-tosa-service-item h4 {
        margin-bottom: .5rem;
    }

    .home-banho-tosa-service-item p {
        margin-bottom: .5rem;
    }

    .home-banho-tosa-service-image {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-banho-tosa-service-image img {
        width: 105%;
        position: initial;
    }
}

@media only screen and (max-width: 1008px) {
    .home-banho-tosa-service-content {
        width: 100%;
        padding: 1rem 1rem;
        /* border: solid red; */
    }

    .home-banho-tosa-service-box {
        width: 100%;
        padding-top: 1.5rem;
        flex-direction: column;
        align-items: center;
        /* border: solid red; */
    }

    .home-banho-tosa-service-item {
        text-align: center;
        padding: 1rem 0;
    }

    .home-banho-tosa-service-title {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        /* margin-bottom: 4px; */
    }

    .home-banho-tosa-service-icon {
        width: 40px;
        height: 40px;
        margin-right: 0;
    }

    .home-banho-tosa-service-item h4,
    .home-banho-tosa-service-item p {
        font-size: 1rem;
    }

    .home-banho-tosa-service-image {
        width: 50%;
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-banho-tosa-service-image img {
        width: 105%;
        position: initial;
    }
}

@media only screen and (max-height: 675px) and (min-width: 1400px) {

    .home-banho-tosa-service-item h4,
    .home-banho-tosa-service-item p {
        font-size: .8rem;
    }
}