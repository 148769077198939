.navbar-small-container {
    width: 100%;
    height: 52px;
    background-color: var(--clr-secondary-blue);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: all 300ms ease-in-out;
    /* border: solid green; */
}

.navbar-small-sticky {
    transform: translate3d(0, -100%, 0);
}

.navbar-small-content {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: solid red; */
}

.navbar-small-links {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--clr-neutral-black80);
    text-decoration: none;
    /* border: solid purple; */
}

.navbar-small-links p {
    font-family: 'Rage', cursive;
    font-size: 1.5rem;
    color: #fff;
}

.navbar-small-logo {
    width: 32px;
    height: 32px;
    background-image: url('../../../assets/navbar-small-logo.png');
    background-size: contain;
    transition: .1s;
}

.navbar-small-logo:hover {
    /*     background-image: url('../../../assets/home/bruno-logo-footer-hover.png');
 */
    transform: scale(1.05);
}

.navbar-small-menu-icon {
    width: 24px;
    height: 24px;
    background-image: url('../../../assets/navbar-small-menu-icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
}