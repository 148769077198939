.banho-tosa-container {
    width: 100%;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.banho-tosa-large-bg:nth-of-type(1) {
    width: 100%;
    min-height: 100vh;
    background-image: url('../../../assets/paw-pattern-blue-foam-bg.jpg');
    background-size: cover;
    background-position: center;
    /* border: solid red; */
}

.banho-tosa-large-bg:nth-of-type(2) {
    width: 100%;
    background-image: url('../../../assets/bubble-foam-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* border: solid red; */
}

.banho-tosa-small-bg {
    width: 83.33%;
    max-width: 1400px;
    margin: 0 auto;
    /* border: solid red; */
    position: relative;
}

@media only screen and (min-width: 1600px) {
    .banho-tosa-large-bg:nth-of-type(1) {
        min-height: fit-content;
        padding-bottom: 4rem;
    }

    .banho-tosa-large-bg:nth-of-type(2) {
        width: 100%;
        background-size: cover;
        background-position: 0% 20%;
        background-repeat: no-repeat;
        /* border: solid red; */
    }
}

@media only screen and (max-width: 1200px) {
    .banho-tosa-large-bg:nth-of-type(1) {
        min-height: fit-content;
    }
}

@media only screen and (max-width: 768px) {
    .banho-tosa-large-bg:nth-of-type(2) {
        background-position: top;
        background-size: 310%;
    }

    .banho-tosa-small-bg {
        width: 100%;
    }
}

@media only screen and (min-width: 1600px) and (max-height: 675px) {}