.banho-tosa-benefits-container {
    width: 83.33%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    /* border: solid green; */
}

.banho-tosa-benefits-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid red; */
}

.banho-tosa-benefits-box01,
.banho-tosa-benefits-box02 {
    width: 50%;
    display: flex;
    align-items: center;
    background-color: #fff;
}

.banho-tosa-benefits-box01 {
    justify-content: flex-end;
    /* border: solid purple; */
}

.banho-tosa-benefits-box02 {
    justify-content: flex-start;
    /* border: solid purple; */
}

.banho-tosa-benefits-icon {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.banho-tosa-benefits-icon-01 {
    background-image: url('../../../assets/trimming.svg');
}

.banho-tosa-benefits-icon-02 {
    background-image: url('../../../assets/pet-grooming.svg');
}

.banho-tosa-benefits-icon-03 {
    background-image: url('../../../assets/paw.svg');
}

.banho-tosa-benefits-icon-04 {
    background-image: url('../../../assets/beauty-saloon.svg');
}

.banho-tosa-benefits-item {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    /* border: solid green; */
}

.banho-tosa-benefits-item h4,
.banho-tosa-benefits-item p {
    color: var(--clr-secondary-blue);
}

.banho-tosa-benefits-item svg {
    fill: var(--clr-secondary-blue);
}

@media only screen and (max-width: 1400px) {}

@media only screen and (max-width: 1200px) {
    .banho-tosa-benefits-container {
        margin-bottom: 3rem;
        /* border: solid green; */
    }
}

@media only screen and (max-width: 640px) {
    .banho-tosa-benefits-container {
        margin-bottom: 4rem;
        padding: 0;
    }

    .banho-tosa-benefits-content {
        flex-direction: column;
        /* border: solid red; */
    }

    .banho-tosa-benefits-box01,
    .banho-tosa-benefits-box02 {
        width: 100%;
        justify-content: center;
    }

    .banho-tosa-benefits-item {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        /* border: solid green; */
    }

    .banho-tosa-benefits-item div:nth-of-type(1) {
        padding-right: 0;
    }
}