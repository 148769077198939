.brands-container {
    width: 100%;
    padding: 1rem 2rem 8rem 2rem;
    /* border: solid red; */
}

.brands-content {
    width: 100%;
}

.brands-content h2 {
    text-align: center;
    font-family: 'Rage', cursive;
    font-size: clamp(2rem, 1.7857rem + 0.9524vw, 2.5rem);
    color: var(--clr-secondary-blue);
    margin-bottom: 4rem;
}

.brands-content div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    /* border: solid red; */
}

.brands-content img {
    width: 16.66%;
}

@media only screen and (max-width: 1200px) {
    .brands-container {
        padding: 1rem 2rem 6rem 2rem;
        /* border: solid red; */
    }

    .brands-content h2 {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 640px) {
    .brands-container {
        padding: 1rem 2rem 4rem 2rem;
        /* border: solid red; */
    }

    .brands-content {
        height: fit-content;
        text-align: center;
    }

    .brands-content div {
        flex-direction: column;
    }

    .brands-content img {
        width: 50%;
    }
}