nav {
  width: 100%;
  height: 64px;
  margin: auto;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 300ms ease-in-out;
  /* border: solid green; */
}

.navbar-container {
  width: 83.33%;
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: solid purple; */
}

.navbar-logo p {
  font-family: 'Rage', cursive;
  font-size: 1.7rem;
  color: var(--clr-secondary-blue);
  transition: all .1s;
}

.navbar-logo p:hover {
  color: var(--clr-secondary-blue);
  text-shadow: 0 0 3px #c2e4f7;
  transform: scale(1.01);
}

.navbar-links {
  width: fit-content;
  height: 100%;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* border: solid lightblue; */
}

.navbar-container li a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 500;
  line-height: 18px 18px;
  color: var(--clr-neutral-black80);
  text-decoration: none;
  padding: 8px;
}

.navbar-container li:not(:nth-child(1)) {
  padding-left: 16px;
}

.navbar-container li a.active {
  color: var(--clr-secondary-blue);
  text-shadow: 0 0 3px #c2e4f7;
}

.navbar-container li a:hover {
  color: var(--clr-secondary-blue);
  text-shadow: 0 0 3px #c2e4f7;
}

.navbar-icons {
  height: 100%;
}

.navbar-icons ul {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-icons ul li a svg {
  fill: var(--clr-secondary-blue);
}

.navbar-icons ul li a:hover svg {
  transform: scale(1.05);
}

.navbar-sticky {
  transform: translate3d(0, -100%, 0);
}

.navbar-scroll-up {
  background-color: var(--clr-secondary-blue);
}

.navbar-scroll-up .navbar-logo p {
  color: #fff;
}

.navbar-scroll-up .navbar-container .navbar-links li a {
  color: var(--clr-neutral-black20);
}

.navbar-scroll-up .navbar-container .navbar-links li a:hover {
  color: #fff;
}

.navbar-scroll-up .navbar-container ul li a {
  color: #fff;
}

.navbar-scroll-up .navbar-container ul li a.active {
  color: #fff;
}

.navbar-scroll-up .navbar-icons ul li a svg {
  fill: #fff;
}

.navbar-scroll-down {
  background-color: #fff;
}

@media only screen and (max-width: 1008px) {
  .navbar-logo p {
    font-size: 1.3rem;
  }
}