@import url('https://fonts.googleapis.com/css2?family=Hind+Guntur&family=Josefin+Sans&family=Paytone+One&family=Radio+Canada&display=swap');

@font-face {
  font-family: 'Rage';
  src: url('./fonts/RAGE.TTF');
}

.App {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  /* border: solid red; */
}

.App-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  /* border: solid blue; */
}

.app-content {
  width: 100%;
}

.show-overflow {
  overflow-y: initial;
}

.hide-overflow {
  height: 100vh;
  overflow-y: hidden;
}

:root {
  --clr-neutral-black100: #111111;
  --clr-neutral-black80: #3D3D3D;
  --clr-neutral-black60: #666666;
  --clr-neutral-black40: #858585;
  --clr-neutral-black20: #B8B8B8;
  --clr-neutral-white: #FAFAFA;
  --clr-neutral-grey: #F6F6F6;
  --clr-primary-blue: #daebfb;
  --clr-secondary-blue: #00477A;
  --clr-tertiary-blue: #7a9cba;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  /* font-family: "Readex Pro", sans-serif; */
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  color: var(--clr-neutral-black80);
  /* -ms-overflow-style: none;  
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow-y: scroll;  */
}

/* body::-webkit-scrollbar {
    display: none; 
} */

body a {
  text-decoration: none;
  color: var(--clr-neutral-black80);
}

body a:hover {
  text-decoration: none;
  color: unset;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

h1 {
  font-family: 'Paytone One', sans-serif;
  font-size: clamp(1.5rem, 1.1364rem + 1.8182vw, 2.5rem);
  font-weight: 100;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Josefin Sans';
  font-size: 1.4rem;
  font-weight: 100;
  line-height: 1.6rem;
}

p {
  font-family: 'Radio Canada', sans-serif;
  font-size: 1rem;
  line-height: 1.35rem;
}

ul {
  list-style-type: none;
}