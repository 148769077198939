.gallery-container {
    padding: 4rem 0 6rem 0;
    position: relative;
    position: 1;
    /* border: solid red; */
}

/* .home-gallery-transparent-bg {
    width: 100%;
    height: 132px;
    background-image: linear-gradient(0deg,
            rgba(204, 204, 204, 0) 0%,
            rgba(255, 255, 255, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
} */

.gallery-content {
    width: 83.33%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    position: 1;
    /* border: solid green; */
}

.gallery-content h2 {
    text-align: center;
    font-family: 'Rage', cursive;
    font-size: clamp(2rem, 1.7857rem + 0.9524vw, 2.5rem);
    color: var(--clr-secondary-blue);
}

.gallery-item {
    height: 320px;
    position: relative;
    text-align: center;
    /* border: solid red */
}

.gallery-item img {
    width: 70%;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    /* border: solid green; */
}

.gallery-item h3 {
    color: var(--clr-neutral-black80);
    font-weight: 400;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.gallery-bg {
    width: 80%;
    height: 220px;
    background-color: #F8F7FC;
    border-radius: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    bottom: .5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.gallery-container .slick-arrow {
    z-index: 1;
}

.gallery-container .slick-prev,
.gallery-container .slick-next {
    top: 55%;
    color: #fff;
    background-color: #164379;
    padding: .25rem;
    border-radius: .25rem;
    transform: translate(0);
}

.gallery-container .slick-prev {
    left: 20px;
}

.gallery-container .slick-next {
    right: 20px;
}

.gallery-container .slick-prev::before,
.gallery-container .slick-next::before {
    color: var(--clr-secondary-blue);
    font-size: 2rem;
}

@media only screen and (max-width: 1200px) {
    .gallery-container {
        padding: 2rem 0 4rem 0;
    }

    .gallery-item {
        height: 240px;
    }

    .gallery-item img {
        top: 0%;
    }

    .gallery-bg {
        height: 180px;
    }

    .gallery-container .slick-prev {
        left: 12px;
    }

    .gallery-container .slick-next {
        right: 12px;
    }
}

@media only screen and (max-width: 1008px) {
    .gallery-item img {
        top: -5%;
    }
}

@media only screen and (max-width: 920px) {
    .gallery-item {
        margin-top: 2rem;
    }

    .gallery-bg {
        height: 154px;
    }

    .gallery-item img {
        top: 0;
    }
}

@media only screen and (max-width: 880px) {
    .gallery-item img {
        top: 5%;
    }
}

@media only screen and (max-width: 760px) {
    .gallery-item img {
        top: 10%;
    }
}

@media only screen and (max-width: 700px) {
    .gallery-item img {
        top: 15%;
    }
}

@media only screen and (max-width: 640px) {
    .gallery-container {
        padding: 3rem 0 4rem 0;
    }

    .gallery-content h2 {
        text-align: center;
    }

    .gallery-item {
        height: 400px;
    }

    .gallery-item img {
        width: 60%;
    }

    .gallery-item h3 {
        font-size: 1.2rem;
    }

    .gallery-bg {
        height: 240px;
    }

    .gallery-container .slick-prev {
        top: 65%;
        left: 40px;
    }

    .gallery-container .slick-next {
        top: 65%;
        right: 40px;
    }
}

@media only screen and (max-width: 480px) {
    .gallery-container {
        padding: 3rem 0 4rem 0;
    }

    .gallery-content h2 {
        text-align: center;
    }

    .gallery-item {
        height: 340px;
    }

    .gallery-item img {
        width: 80%;
        top: -5%;
    }

    .gallery-item h3 {
        font-size: 1.2rem;
    }

    .gallery-bg {
        height: 240px;
    }

    .gallery-container .slick-prev {
        top: 60%;
        left: 24px;
    }

    .gallery-container .slick-next {
        top: 60%;
        right: 24px;
    }
}


@media only screen and (max-width: 400px) {
    .gallery-container {
        padding: 3rem 0 4rem 0;
    }

    .gallery-content h2 {
        text-align: center;
    }

    .gallery-item {
        height: 320px;
    }

    .gallery-item img {
        width: 70%;
        top: 5%;
    }

    .gallery-item h3 {
        font-size: 1.2rem;
    }

    .gallery-bg {
        height: 240px;
    }

    .gallery-container .slick-prev {
        left: 18px;
    }

    .gallery-container .slick-next {
        right: 18px;
    }
}

@media only screen and (max-width: 0px) {}

@media only screen and (max-height: 675px) and (min-width: 1400px) {}