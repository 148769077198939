.headline-container {
    width: 100%;
    /* border: solid red; */
}

.headline-bg-container {
    width: 100%;
    height: 100%;
    /* background-image: url('../../../assets/banho-tosa-cat-bg.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid green; */
}

.headline-content {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    border-radius: 3rem;
    /* border: solid green */
}

.headline-bg-paws {
    width: 100%;
    height: 100%;
    /* background-image: url('../../../assets/banho-tosa-bg-paws.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: -1;
}

.headline-text {
    width: 45%;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    /* border: solid green; */
}

.headline-text p {
    margin-bottom: 2rem;
    white-space: pre-wrap;
}

.headline-image {
    width: 32.5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all .5;
    /* border: solid green; */
}

.headline-image img {
    width: 100%;
}

.headline-image img:nth-of-type(n+2) {
    position: absolute;
}

.headline-image img:nth-of-type(2) {
    opacity: 0;
    z-index: 1;
    animation: show-image 12s ease-in-out infinite;
}

.headline-image img:nth-of-type(3) {
    width: 120%;
    transform: translateX(90%) translateY(90%);
    animation: move-ball 12s ease-in-out infinite;
    z-index: 2;
}

.headline-image img:nth-of-type(4) {
    z-index: 3;
}

@keyframes show-image {
    0% {
        opacity: 1;
    }

    7% {
        opacity: 1;
    }

    8% {
        opacity: 0;
    }

    62% {
        opacity: 0;
    }

    63% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-ball {
    0% {
        transform: translateX(90%) translateY(90%);
    }

    15% {
        transform: translateX(-90%) translateY(-90%);
    }

    50% {
        transform: translateX(-90%) translateY(-90%);
    }

    75% {
        transform: translateX(90%) translateY(90%);
    }

    100% {
        transform: translateX(90%) translateY(90%);
    }
}

@media only screen and (max-width: 1200px) {
    .headline-content {
        padding: 0 2rem;
        gap: 2rem;
    }

    .headline-text {
        width: 50%;
        margin-bottom: 0;
        padding: 0;
    }

    .headline-text p {
        margin-bottom: 2rem;
    }

    .headline-image {
        width: 50%;
        height: 100%;
    }
}

@media only screen and (max-width: 1008px) {
    .headline-content {
        padding: 0 4rem;
        gap: 2rem;
    }

    .headline-text {
        width: 50%;
        margin-bottom: 0;
        padding: 0;
    }

    .headline-text p {
        margin-bottom: 2rem;
    }

    .headline-image {
        width: 50%;
        height: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .headline-content {
        width: 100%;
        padding: 0;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 2rem;
        text-align: center;
    }

    .headline-text {
        width: 90%;
        align-items: center;
    }

    .headline-text p {
        margin-bottom: 2rem;
    }

    .headline-image {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        /* border: solid green; */
    }

    .headline-image img {
        width: 100%;
    }
}