.home-hero-benefits-container {
    width: 100%;
    padding-bottom: 2rem;
    /* border: solid green; */
}

.home-hero-benefits-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.home-hero-benefits-box {
    width: 50%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* border: solid red; */
}

.home-hero-benefits-item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    margin: 0 .5rem;
    padding: 2rem 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
    /* border: solid green; */
}

.home-hero-benefits-icon {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.home-hero-benefits-icon-01 {
    background-image: url('../../../assets/dog-food.svg');
}

.home-hero-benefits-icon-02 {
    background-image: url('../../../assets/bathing.svg');
}

.home-hero-benefits-icon-03 {
    background-image: url('../../../assets/collar.svg');
}

.home-hero-benefits-icon-04 {
    background-image: url('../../../assets/sales.svg');
}

.home-hero-benefits-item h4,
.home-hero-benefits-item p {
    font-family: 'Radio Canada', sans-serif;
    font-size: .9rem;
    color: var(--clr-secondary-blue);
}

.home-hero-benefits-item h4 {
    margin-bottom: .5rem;
}

.home-hero-benefits-item svg {
    fill: var(--clr-secondary-blue);
}

.home-hero-benefits-item div:nth-of-type(1) {
    padding-right: 1rem;
}

@media only screen and (max-width: 1200px) {

    .home-hero-benefits-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        /* border: solid green; */
    }

    .home-hero-benefits-item div:nth-of-type(1) {
        padding-right: 0;
    }

    .home-hero-benefits-item h4,
    .home-hero-benefits-item p {
        font-size: .8rem;
    }

    .home-hero-benefits-box {
        min-height: 180px;
    }
}

@media only screen and (max-width: 1038px) {
    .home-hero-benefits-box {
        min-height: 200px;
    }
}


@media only screen and (max-width: 1008px) {
    .home-hero-benefits-container {
        /* border: solid green; */
    }

    .home-hero-benefits-content {
        width: 100%;
    }

    .home-hero-benefits-box {
        width: 100%;
        padding-bottom: 1rem;
        /* border: solid red; */
    }

    .home-hero-benefits-item {
        width: 50%;
        min-height: 160px;
    }

    .home-hero-benefits-item h4,
    .home-hero-benefits-item p {
        font-size: .9rem;
    }

    .home-hero-benefits-box {
        width: 100%;
        justify-content: space-between;
        /* border: solid red; */
    }

    .home-hero-benefits-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        /* border: solid green; */
    }

    .home-hero-benefits-item div:nth-of-type(1) {
        padding-right: 0;
    }
}

@media only screen and (max-width: 640px) {
    .home-hero-benefits-container {
        padding-bottom: 0rem;
        /* border: solid green; */
    }

    .home-hero-benefits-box {
        width: 100%;
        justify-content: space-between;
        /* border: solid red; */
    }

    .home-hero-benefits-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        /* border: solid green; */
    }

    .home-hero-benefits-item div:nth-of-type(1) {
        padding-right: 0;
    }
}