.home-hero-container {
    margin-bottom: 3rem;
    /* border: solid red; */
}

.home-hero-slider-container {
    width: 100%;
    height: 420px;
    margin-top: 88px;
    /* border: solid red; */
}

.home-hero-slider {
    height: 332px;
    margin-top: 60px;
    /* border: solid green; */
}

.home-hero-slider-content {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.home-hero-slider-content-bg {
    width: 98%;
    height: 100%;
    /* background-image: url('../../../assets/paw-pattern-blue-bg.jpg'); */
    background-size: cover;
    background-position: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 3rem;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
}

.home-hero-headline {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 2;
    /* border: solid green; */
}

.home-hero-headline p {
    width: 90%;
    margin-bottom: 2rem;
}

.home-hero-image {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.home-hero-image img {
    width: 100%;
    position: absolute;
    top: -4rem;
}

@media only screen and (min-width: 1600px) {
    .home-hero-image img {
        width: 90%;
        position: absolute;
        top: -4rem;
    }
}

@media only screen and (max-width: 1200px) {
    .home-hero-container {
        margin-bottom: 3rem;
    }

    .home-hero-slider {
        height: 290px;
        /* border: solid red; */
    }

    .home-hero-slider-container {
        height: fit-content;
        margin-top: 32px;
        /* border: solid red; */
    }

    .home-hero-slider-content {
        width: 100%;
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .home-hero-image img {
        width: 80%;
        top: -1rem;
    }

    .home-hero-headline h1 {
        font-size: 1.5rem;
    }

    .home-hero-headline h1 {
        font-size: 1.5rem;
    }

    .home-hero-headline p {
        width: 100%;
        margin-bottom: 1rem;
    }

    .home-hero-image {
        width: 50%;
    }

    .home-hero-hearts {
        top: -25%;
        left: -15%;
    }
}

@media only screen and (max-width: 1008px) {
    .home-hero-container {
        margin-bottom: 8rem;
    }

    .home-hero-slider {
        height: 332px;
        /* border: solid red; */
    }

    .home-hero-slider-container {
        height: 320px;
        margin-top: 32px;
        /* border: solid red; */
    }

    .home-hero-slider-content {
        width: 100%;
        height: 280px;
    }

    .home-hero-image img {
        width: 100%;
        top: 1rem;
    }

    .home-hero-headline h1 {
        font-size: 1.5rem;
    }

    .home-hero-headline h1 {
        font-size: 1.5rem;
    }

    .home-hero-headline p {
        width: 100%;
        margin-bottom: 1rem;
    }

    .home-hero-headline {
        width: 45%;
    }

    .home-hero-image {
        width: 45%;
    }

}

@media only screen and (max-width: 640px) {
    .home-hero-container {
        margin-bottom: 3rem;
    }

    .home-hero-container {
        min-height: 85vh;
        margin-bottom: 0;
        /* border: solid red; */
    }

    .home-hero-slider-container {
        margin-top: 80px;
        min-height: 75vh;
    }

    .home-hero-slider {
        min-height: 75vh;
        margin: 0 0 10px 0;
        /* border: solid green; */
    }

    .home-hero-slider-content-bg {
        background-position: 9% 0%;
        background-size: 346%;
    }

    .home-hero-slider-content {
        width: 100%;
        height: 100%;
        position: relative;
        gap: 4rem;
    }

    .home-hero-headline {
        width: 90%;
        height: 50%;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        /* border: solid red; */
    }

    .home-hero-headline h1 {
        margin-bottom: .5rem;
    }

    .home-hero-headline p {
        font-size: 0.9rem;
        margin-bottom: 2rem;
    }

    .home-hero-image {
        width: 100%;
        height: 40%;
        padding-top: 2rem;
        /* border: solid red; */
    }

    .home-hero-image img {
        width: 60%;
        top: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .home-hero-image img {
        width: 70%;
    }
}

@media only screen and (max-width: 420px) {
    .home-hero-image img {
        width: 90%;
    }
}

@media only screen and (max-height: 675px) and (min-width: 1400px) {
    .home-hero-container {
        margin-bottom: 1rem;
    }

    .home-hero-slider-container {
        margin-top: 30px;
        /* border: solid red; */
    }

    .home-hero-image img {
        top: -2rem;
    }
}