.contact-form-container {
    width: 100%;
}

form {
    width: 100%;
    min-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /* border: solid green; */
}

.contact-form-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.contact-form-opening-hours {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 2rem 0;
    /* border: solid red; */
}

.contact-form-opening-hours-item {
    width: 33.33%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    transition: all .3s;
    /* border: solid green; */
}

.contact-form-opening-hours-item:nth-of-type(2) {
    border-left: solid var(--clr-primary-blue);
    border-right: solid var(--clr-primary-blue);
}

.contact-form-opening-hours-item h4,
.contact-form-opening-hours-item p {
    font-family: 'Josefin Sans', sans-serif;
    color: var(--clr-secondary-blue);
}

.contact-form-opening-hours-item svg {
    fill: var(--clr-secondary-blue);
}

.contact-form-headline-box a {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.3rem;
    color: var(--clr-secondary-blue);
}

.contact-form-headline-box a:hover {
    color: var(--clr-tertiary-blue);
    text-shadow: 0 0 3px #c2e4f7;
}

.contact-form-headline-box a:visited {
    color: var(--clr-secondary-blue);
}

.contact-form-input-box,
.contact-form-textarea-box {
    width: 50%;
    padding: 0 4rem;
    margin-bottom: 3rem;
    /* border: solid green; */
}

.contact-form-input-item,
.contact-form-textarea-item {
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
}

.contact-form-input-item:nth-of-type(3),
.contact-form-textarea-item {
    margin-bottom: 0;
}

.contact-form-input-label>input {
    width: 100%;
    height: 3rem;
    font-size: 1.0625rem;
    padding-left: 0.875rem;
    line-height: 147.6%;
    padding-top: 1rem;
    padding-bottom: 0;
    border: 0.125rem solid var(--clr-tertiary-blue);
    border-radius: .7rem;
}

.contact-form-textarea-label>textarea {
    width: 100%;
    height: 12.1rem;
    padding: 1.5rem 1rem;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.1em;
    font-weight: 100;
    color: var(--clr-neutral-black80);
    line-height: 1.4rem;
    background-color: var(--clr-primary-blue);
    border: 0.125rem solid var(--clr-primary-blue);
    border-radius: 1rem;
    resize: none;
    outline: none;
    transition: .3s;
}

.contact-form-input-label>input:focus,
.contact-form-textarea-label>textarea:focus {
    outline: var(--clr-primary-blue);
}

.contact-form-textarea-label>textarea:focus {
    border: 0.125rem solid var(--clr-primary-blue);
}

.contact-form-input-span,
.contact-form-textarea-span {
    position: absolute;
    top: 0.9375rem;
    left: 0.875rem;
    line-height: 147.6%;
    transition: top .2s;
    color: var(--clr-secondary-blue)
}

.contact-form-textarea-span {
    color: var(--clr-secondary-blue);
}

.contact-form-input-label>input:hover,
.contact-form-textarea-label>textarea:hover {
    border-color: var(--clr-primary-blue);
}

.contact-form-input-label>input:focus+.contact-form-input-span,
.contact-form-input-label>input:valid+.contact-form-input-span,
.contact-form-textarea-label>textarea:focus+.contact-form-textarea-span,
.contact-form-textarea-label>textarea:valid+.contact-form-textarea-span {
    top: 5px;
    font-size: 0.7rem;
    margin-bottom: 32px;
}

.contact-form-input-label>input:focus+.contact-form-input-span,
.contact-form-textarea-label>textarea:focus+.contact-form-textarea-span {
    color: var(--clr-tertiary-blue);
}

.contact-form-input-label>input:focus,
.contact-form-textarea-label>textarea:focus {
    border-color: var(--clr-tertiary-blue);
}

.contact-form-textarea-label>textarea:valid {
    background-color: #fff;
}

.contact-form-textarea-label>textarea:hover {
    background-color: #fff;
}

.contact-form-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.contact-form-recaptcha,
.contact-form-btn {
    width: 50%;
    display: grid;
    place-items: center;
}

.contact-form-success-msg {
    width: 100%;
    height: 380px;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    /* border: solid green; */
}

.contact-form-success-msg img {
    width: 40%;
}

@media only screen and (min-width: 1600px) {
    form {
        min-height: 600px;
    }

    .contact-form-opening-hours {
        padding: 0rem 0 2rem 0;
    }

    .contact-form-opening-hours-item {
        padding: 0rem 0rem 1rem 0rem;
        gap: 2rem;
    }
}


@media only screen and (max-width: 1200px) {

    .contact-form-input-box,
    .contact-form-textarea-box {
        width: 50%;
        padding: 0 1rem;
        margin-bottom: 3rem;
        /* border: solid green; */
    }

    .contact-form-success-msg img {
        width: 60%;
    }
}

@media only screen and (max-width: 1008px) {
    #form:target {
        scroll-margin-top: 12px;
    }

    form {
        height: fit-content;
        padding: 1rem 0 6rem 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .contact-form-content {
        flex-direction: column;
    }

    .contact-form-opening-hours {
        margin-bottom: 2rem;
        padding: 1rem 0;
        flex-direction: column;
        border: none;
        box-shadow: none;
    }

    .contact-form-opening-hours-item {
        width: 90%;
    }

    .contact-form-opening-hours-item:nth-of-type(2) {
        border-left: none;
        border-right: none;
        border-top: solid var(--clr-tertiary-blue);
        border-bottom: solid var(--clr-tertiary-blue);
    }

    .contact-form-input-box,
    .contact-form-textarea-box {
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 3rem;
        /* border: solid green; */
    }

    .contact-form-submit {
        flex-direction: column;
        gap: 2rem;
    }

    .contact-form-recaptcha,
    .contact-form-btn {
        width: 100%;
    }

    .contact-form-content a {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 1.2rem;
        color: var(--clr-secondary-blue);
        padding-bottom: 2rem;
    }

    .contact-form-success-msg {
        margin-bottom: 6rem;
    }

    .contact-form-success-msg img {
        width: 70%;
    }
}

@media only screen and (min-width: 1600px) and (max-height: 675px) {
    form {
        min-height: 320px;
    }

    .contact-form-opening-hours-item {
        padding: 0;
    }

    .contact-form-input-box,
    .contact-form-textarea-box {

        margin-bottom: .5rem;
        /* border: solid green; */
    }
}