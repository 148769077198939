.home-container {
    width: 100%;
    position: relative;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    /* padding-bottom: 4rem; */
}

.link-preview {
    display: none;
}

.home-large-bg:nth-of-type(1) {
    width: 100%;
    /* min-height: 90vh; */
    background-image: linear-gradient(0deg,
            rgb(218, 235, 251) 0%,
            rgba(255, 255, 255, 1) 40%,
            rgba(255, 255, 255, 1) 100%);
    position: relative;
    /* border: solid red; */
}

.home-large-bg:nth-of-type(2) {
    width: 100%;
    background-image: url('../../../assets/paw-pattern-blue-bg.jpg');
    background-size: contain;
    background-position: top;
}

.home-small-bg {
    width: 83.33%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.home-small-bg:nth-child(2) {
    background-color: #fff;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* @media only screen and (min-width: 1300px) and (min-height: 700px) {
    .home-large-bg:nth-of-type(1) {
        min-height: fit-content;
    }
}

@media only screen and (min-width: 1400px) {
    .home-large-bg:nth-of-type(1) {
        min-height: fit-content;
    }
}

@media only screen and (max-width: 1008px) {
    .home-large-bg:nth-of-type(1) {
        min-height: fit-content;
    }
} */

@media only screen and (max-width: 768px) {
    .home-small-bg:nth-child(1) {
        width: 90%;
    }

    .home-large-bg:nth-of-type(2) {
        width: 100%;
        background-size: 300%;
    }

    .home-small-bg:nth-of-type(2) {
        width: 100%;
        padding: 2rem 0;
    }
}

@media only screen and (max-width: 640px) {
    .home-large-bg:nth-of-type(1) {
        min-height: 90vh;
    }

    .home-small-bg:nth-child(1) {
        width: 90%;
    }

    .home-large-bg:nth-of-type(2) {
        width: 100%;
        background-size: 300%;
    }

    .home-small-bg:nth-of-type(2) {
        width: 100%;
        padding: 2rem 0;
    }
}

@media only screen and (min-width: 1600px) and (max-height: 675px) {
    .home-large-bg:nth-of-type(1) {
        min-height: 100vh;
    }
}