.sidebar-menu {
    background-color: var(--clr-primary-blue);
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sidebar-menu.active {
    left: 0;
    transition: 350ms;
}

.sidebar-menu-items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.sidebar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-toggle a {
    color: var(--clr-neutral-black80);
}

.sidebar-toggle a:hover {
    /* color: #b0dbff; */
    color: #fff;
}

.sidebar-toggle-header {
    width: 100%;
    padding-left: 32px;
    display: flex;
    justify-content: flex-start;
}

.sidebar-text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.sidebar-text a {
    color: var(--clr-secondary-blue);
    text-decoration: none;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.sidebar-text a:hover {
    background-color: #b0dbff;
    color: #fff;
}

.sidebar-text div {
    width: 32px;
    height: 32px;
    background-size: contain;
}

.sidebar-text span {
    margin-left: 16px;
}

.sidebar-icons {
    width: 100%;
    position: absolute;
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 2rem;
    list-style-type: none;
    /* border: solid red; */
}

.sidebar-icons svg {
    fill: var(--clr-secondary-blue);
}

.sidebar-icons svg:hover {
    fill: #b0dbff;
}