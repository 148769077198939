.atendimento-container {
    width: 100%;
    min-height: 100vh;
    background-image: url('../../../assets/paw-pattern-blue-bg.jpg');
    background-size: contain;
    background-position: center;
    padding: 1px 0 6rem 0;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.atendimento-content {
    width: 83.33%;
    max-width: 1400px;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* border: solid red; */
}

.atendimento-headline-box {
    width: 100%;
}

.atendimento-headline {
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
    background-color: var(--clr-primary-blue);
    border-radius: 10px;
    /* border: solid red; */
}

@media only screen and (min-width: 1600px) {
    .atendimento-headline {
        margin-top: 6rem;
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 1200px) {
    .atendimento-headline p {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 1008px) {
    .atendimento-container {
        min-height: 60vh;
        padding: 1px 0 0 0;
        background-size: 180%;
    }

    .atendimento-content {
        min-height: 60vh;
    }

    .atendimento-headline p {
        font-size: 1rem;
    }
}


@media only screen and (max-width: 640px) {
    .atendimento-container {
        background-size: 300%;
        padding: 1px 0 0 0;
    }

    .atendimento-content {
        width: 90%;
    }

    .atendimento-headline-box {
        text-align: center;
    }

    .atendimento-headline {
        margin-top: 4rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (min-width: 1600px) and (max-height: 675px) {
    .atendimento-container {
        padding: 1px 0 5rem 0;
    }

    .atendimento-headline {
        margin: 4rem 0 1rem 0;
    }

    .atendimento-headline h1 {
        font-size: 2rem;
    }
}