.banho-tosa-gallery-container {
    padding: 0 4rem 4rem 4rem;
    /* border: solid red; */
}

.banho-tosa-gallery-content {
    width: 100%
        /* background-color: #fff; */
}

.banho-tosa-gallery-content h2 {
    text-align: center;
    font-family: 'Rage', cursive;
    font-size: clamp(2rem, 1.7857rem + 0.9524vw, 2.5rem);
    color: var(--clr-secondary-blue);
    margin-bottom: 2rem;
}

.banho-tosa-gallery-item {
    min-height: 380px;
    position: relative;
    text-align: center;
    /* border: solid red; */
}

.banho-tosa-gallery-item img {
    width: 50%;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    transition: all .5s;
    /* border: solid red; */
}

.banho-tosa-gallery-item h3 {
    color: var(--clr-neutral-black80);
    font-weight: 400;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.banho-tosa-gallery-bg {
    width: 80%;
    height: 240px;
    background-color: #F8F7FC;
    border-radius: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    bottom: .5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.banho-tosa-gallery-container .slick-arrow {
    z-index: 1;
}

.banho-tosa-gallery-container .slick-prev,
.banho-tosa-gallery-container .slick-next {
    top: 50%;
    color: #fff;
    background-color: #164379;
    padding: .25rem;
    border-radius: .25rem;
    transform: translate(0);
}

.banho-tosa-gallery-container .slick-prev {
    left: 32px;
}

.banho-tosa-gallery-container .slick-next {
    right: 32px;
}

.banho-tosa-gallery-container .slick-prev::before,
.banho-tosa-gallery-container .slick-next::before {
    color: var(--clr-secondary-blue);
    font-size: 2rem;
}

@media only screen and (max-width: 1200px) {
    .banho-tosa-gallery-item {
        min-height: 280px;
    }

    .banho-tosa-gallery-item img {
        top: 0rem;
    }
}

@media only screen and (max-width: 1008px) {
    .banho-tosa-gallery-container {
        padding: 0 1rem 4rem 1rem;
        /* border: solid red; */
    }

    .banho-tosa-gallery-content h2 {
        margin-bottom: 0;
    }

    .banho-tosa-gallery-item {
        min-height: 260px;
    }

    .banho-tosa-gallery-item img {
        top: 1rem;
    }

    .banho-tosa-gallery-bg {
        height: 180px;
    }

    .banho-tosa-gallery-container .slick-prev {
        top: 65%;
        left: 8px;
    }

    .banho-tosa-gallery-container .slick-next {
        top: 65%;
        right: 8px;
    }
}

@media only screen and (max-width: 640px) {
    .banho-tosa-gallery-container {
        padding: 0 0 4rem 0;
        /* border: solid red; */
    }

    .banho-tosa-gallery-content h2 {
        text-align: center;
        line-height: 2rem;
        margin-bottom: 0;
    }

    .banho-tosa-gallery-item {
        min-height: 420px;
    }

    .banho-tosa-gallery-item img {
        width: 35%;
        top: 2rem;
    }

    .banho-tosa-gallery-bg {
        height: 320px;
    }

    .banho-tosa-gallery-container .slick-prev {
        top: 55%;
        left: 44px;
    }

    .banho-tosa-gallery-container .slick-next {
        top: 55%;
        right: 44px;
    }
}

@media only screen and (max-width: 500px) {
    .banho-tosa-gallery-container {
        padding: 0 0 4rem 0;
        /* border: solid red; */
    }

    .banho-tosa-gallery-content h2 {
        text-align: center;
        line-height: 2rem;
        margin-bottom: 0;
    }

    .banho-tosa-gallery-item {
        min-height: 420px;
    }

    .banho-tosa-gallery-item img {
        width: 40%;
        top: 2rem;
    }

    .banho-tosa-gallery-bg {
        height: 320px;
    }
}

@media only screen and (max-width: 420px) {
    .banho-tosa-gallery-container {
        padding: 0 0 4rem 0;
        /* border: solid red; */
    }

    .banho-tosa-gallery-content h2 {
        text-align: center;
        line-height: 2rem;
        margin-bottom: 0;
    }

    .banho-tosa-gallery-item {
        min-height: 360px;
    }

    .banho-tosa-gallery-item img {
        width: 50%;
        top: 0rem;
    }

    .banho-tosa-gallery-item h3 {
        font-size: 1.2rem;
    }

    .banho-tosa-gallery-bg {
        height: 240px;
    }

    .banho-tosa-gallery-container .slick-prev {
        top: 58%;
        left: 24px;
    }

    .banho-tosa-gallery-container .slick-next {
        top: 58%;
        right: 24px;
    }
}

@media only screen and (min-width: 1600px) and (max-height: 675px) {
    .banho-tosa-gallery-container {
        padding-top: 6rem;
    }
}